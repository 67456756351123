import Link from "next/link"
import Image from "next/legacy/image"
import { ArticleContent } from "@/common/member/article-content/article-content"
import styles from "./article-card-with-image.module.css"
import classNames from "classnames"

export default function Card({
  article,
  showImage = true,
  showTag = true,
  showTags = false,
  showDateline = true
}) {
  return (
    <li
      className={classNames(styles.card, "member-article-card", {
        "is-new": article?.isNew
      })}>
      <Link href={article?.url} prefetch={false} legacyBehavior>
        <a>
          {showImage && (
            <div className="image">
              <figure>
                {article?.image && (
                  <Image
                    src={article.image.url}
                    sizes={article.image.sizes}
                    alt={article.image.alt}
                    layout="fill"
                    loading="lazy"
                  />
                )}
              </figure>
            </div>
          )}
          <div className="content">
            <ArticleContent
              article={article}
              showTag={showTag}
              showDateline={showDateline}
              showExcerpt={false}
              showTags={showTags}
            />
          </div>
        </a>
      </Link>
    </li>
  )
}
